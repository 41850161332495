import { usePathname } from 'next/navigation';
import { routeNames } from '@/constants/routes';

interface IProps {
  href: string;
  isTag: boolean;
}

export const useActiveLink = ({ href, isTag }: IProps) => {
  const pathname = usePathname();
  const transformUrl = String(pathname?.split('#').shift()?.split('/')?.filter(Boolean));
  const transformHref = String(href?.split('#').shift()?.split('/')?.filter(Boolean));
  const isActive = transformUrl && transformHref && transformUrl === transformHref;

  if (isTag) {
    const isCaseStudyPage = String(transformUrl).includes('case-studies') && href === routeNames.ourWork;

    return isActive || isCaseStudyPage;
  }

  const isApplyPageSelected = String(transformUrl).includes('careers') && href === routeNames.careers;

  const isInsightsPage =
    (String(transformUrl).includes('blog') || String(transformUrl).includes('event')) && href === routeNames.insights;

  return isActive || isApplyPageSelected || isInsightsPage;
};
