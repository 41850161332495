import { PropsWithChildren } from 'react';
import { footerContact } from '@/constants/navigations';
import Link from 'next/link';
import { Socials } from '@/components/Socials';

export const CopyRights = ({ children }: PropsWithChildren) => {
  const { socials } = footerContact;

  return (
    <div className="flex flex-col mobile:items-center mobile:flex-row gap-y-[24px]">
      <ul className="flex gap-xs mr-xl">
        {socials.map(({ icon, url }) => (
          <li key={icon}>
            <Link
              scroll={true}
              href={url}
              target="_blank"
              data-testid={`footer-social-${icon}`}
              className="transition-all opacity-100 hover:opacity-[50%]"
              aria-label="Social"
            >
              <Socials
                type={icon}
                classname="fill-black-5"
              />
            </Link>
          </li>
        ))}
      </ul>
      <p
        className="text-left text-sm text-quinary"
        data-testid="copy-rights-year"
      >
        {children}
      </p>
    </div>
  );
};
