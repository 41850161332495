import Link from 'next/link';
import { contactUsLink } from '@/constants/navigations';
import { ConicBackground } from '@/components/Backgrounds/Conic';

export const ContactUsButton = () => {
  return (
    <div
      data-testid="mobile-nav-contact-us-button"
      className="flex justify-center items-center py-[24px] px-[16px] bg-white"
    >
      <Link
        scroll={true}
        href={`${contactUsLink.url}`}
        className={`
          transition-colors duration-300 ease-out border-opacity-50
          relative rounded-full inline-flex items-center overflow-hidden
          h-[36px] w-full border group border-white max-w-[343px] self-center
          isolate
        `}
      >
        <p
          data-testid="header-contact-us-mobile"
          className={`
            transition-colors duration-300 ease-out
            mt-0 text-base leading-base relative
            text-white w-full text-center
          `}
        >
          {contactUsLink.label}
        </p>

        <ConicBackground classname="w-[300%] h-[4000%] z-[-1]" />
      </Link>
    </div>
  );
};
