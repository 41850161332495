'use client';

import { ReactElement } from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { useActiveLink } from '@/hooks/useActiveLink';

interface IProps {
  href: string;
  children: ReactElement | string;
  classname?: string;
  activeClassname?: string;
  containerClassname?: string;
  testId?: string;
}

export const NavLink = ({ href, children, containerClassname, classname, activeClassname, testId }: IProps) => {
  const isActive = useActiveLink({ href, isTag: false });

  return (
    <li
      className={clsx(`inline-flex`, {
        [`${containerClassname}`]: containerClassname,
      })}
      data-testid={testId}
    >
      <Link
        scroll={true}
        href={href}
        className={clsx(
          `inline-flex items-center border-b-[3px] transition-all
           duration-300 ease-out pt-px font-medium h-5`,
          {
            [`${activeClassname}`]: isActive,
            [`${classname}`]: classname,
          },
        )}
      >
        {children}
      </Link>
    </li>
  );
};
