import Link from 'next/link';
import { Dispatch, SetStateAction } from 'react';
import { isActiveMobileLink, mobileNavLinks } from '@/constants/navigations';
import { CollapsibleMenuItem } from '@/components/Layouts/Header/MobileNavOverlay/components/CollapsibleMobileMenuItem';

interface IProps {
  pathname: string;
  activeCollapsibleMenuIndex: number | null;
  setActiveCollapsibleMenuIndex: Dispatch<SetStateAction<number | null>>;
}

export const MobileNavList = ({ activeCollapsibleMenuIndex, setActiveCollapsibleMenuIndex, pathname }: IProps) => {
  return (
    <ul>
      {mobileNavLinks.map(({ id, name, href, submenu, relatedLinks }, index) => {
        return (
          <li
            key={id}
            className="border-b-[1px] border-grey-1"
          >
            {href && !submenu ? (
              <Link
                href={href}
                className={`
                block py-[20px] px-base text-[22px] font-bold leading-[26.4px] 
                cursor-pointer duration-300
                ${isActiveMobileLink(pathname, href, relatedLinks) ? 'text-purple-8' : 'text-black'}
              `}
              >
                {name}
              </Link>
            ) : (
              <CollapsibleMenuItem
                name={name}
                index={index}
                submenu={submenu}
                pathname={pathname}
                setActiveIndex={setActiveCollapsibleMenuIndex}
                isOpened={activeCollapsibleMenuIndex === index}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};
