import Script from 'next/script';

export const CallTracking = () => {
  return (
    <Script
      id="call"
      src="//60286.tctm.xyz/t.js"
      async
      strategy="beforeInteractive"
    />
  );
};
