'use client';

import Link from 'next/link';
import { IHeaderTagProps } from './types';
import clsx from 'clsx';
import { ConicBackground } from '@/components/Backgrounds/Conic';
import { useActiveLink } from '@/hooks/useActiveLink';

export const HeaderTag = ({ tagHref, tagLabel, classname, testId }: IHeaderTagProps) => {
  const isActive = useActiveLink({ href: tagHref, isTag: true });

  return (
    <li
      data-testid={testId}
      className={clsx(
        `
          transition-colors duration-300 ease-out
          relative rounded-full inline-flex items-center overflow-hidden
          w-auto h-[32px] border group hover:cursor-pointer mb-[1px]
          isolate
        `,
        {
          [`${classname}`]: classname,
          'text-white': isActive,
        },
      )}
    >
      <Link
        scroll={true}
        href={tagHref}
        className="w-full h-full px-base flex flex-wrap items-center"
      >
        <span>{tagLabel}</span>
      </Link>

      {isActive && <ConicBackground classname="w-[350%] h-[2000%] z-[-1]" />}
    </li>
  );
};
