import Link from 'next/link';
import { isCareersPages } from '@/helpers/darkMode';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { ISubmenuItem } from '@/components/Layouts/Header/MobileNavOverlay/interfaces';

interface IProps {
  name: string;
  index: number;
  pathname: string;
  isOpened: boolean;
  submenu: ISubmenuItem[] | null;
  setActiveIndex: Dispatch<SetStateAction<number | null>>;
}

export const CollapsibleMenuItem = ({ name, submenu, index, setActiveIndex, isOpened, pathname }: IProps) => {
  const handleMenuItemClick = () => {
    if (isOpened) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const getActiveNavItemClass = useCallback(
    (href: string) =>
      pathname.startsWith(href) ? (isCareersPages(pathname) ? 'text-green-9' : 'text-purple-8') : 'text-black',
    [pathname],
  );

  return (
    <>
      <div
        onClick={handleMenuItemClick}
        className="py-[20px] px-base flex gap-xs items-center cursor-pointer"
      >
        <span className="block text-[22px] font-bold leading-[26.4px]">{name}</span>
        <div
          className={`
            bg-[url(/icons/purple-dropdown-arrow.svg)] bg-center bg-no-repeat bg-contain w-[16px] h-[16px] duration-300
            ${isOpened ? 'rotate-180' : 'rotate-0'}
          `}
        />
      </div>

      <div
        className={`
          grid w-full duration-500 transition-[grid-template-rows] 
          ${isOpened ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'}
        `}
      >
        <div className="overflow-hidden">
          <ul className="pl-[40px] pb-[20px] flex flex-col gap-[8px]">
            {submenu?.map(({ id, name, href }) => {
              return (
                <li
                  key={id}
                  data-testid={`${id}-testid`}
                >
                  {href ? (
                    <Link
                      href={href}
                      className={`block cursor-pointer text-base font-bold leading-[32px] duration-300
                      ${getActiveNavItemClass(href)}
                    `}
                    >
                      {name}
                    </Link>
                  ) : (
                    <div className="inline-block relative">
                      <div className="inline-block text-base font-bold leading-[32px]">{name}</div>
                      <div
                        className="
                        absolute rounded-full border-[1px] border-[#ABEFC6] bg-[#ECFDF3] top-0 right-[-85px]
                        px-[6px] py-[2px] text-[10px] font-semibold leading-[12px] text-[#067647]
                      "
                      >
                        Coming soon
                      </div>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
