import { PAGE_NOT_FOUND_SEGMENT, routeNames } from '@/constants/routes';

export const pagesWithLightMode = ['careers', 'blog', 'events', 'insights'];

export const getRouteSegments = (path: string) => path.split('/').filter(Boolean);

export const is404 = (segments: string[], pathnameSegments: string[]) =>
  segments.filter((segments) => segments !== PAGE_NOT_FOUND_SEGMENT).length === 0 && pathnameSegments.length !== 0;

export const isCareersPage = (topLevelRoute: string) => {
  return routeNames.careers === topLevelRoute;
};

export const isApplyPage = (topLevelRoute: string) => {
  return routeNames.apply === topLevelRoute;
};

export const isWorkingPage = (topLevelRoute: string) => {
  return routeNames.workingAtSyberry === topLevelRoute;
};

export const isCareersPages = (pathname: string) => {
  return (
    isCareersPage(getRouteSegments(pathname)[0]) ||
    isCareersPage('/' + getRouteSegments(pathname)[0]) ||
    isApplyPage(getRouteSegments(pathname)[0]) ||
    isApplyPage('/' + getRouteSegments(pathname)[0])
  );
};

export const isCasePage = (topLevelRoute: string) => {
  return routeNames.caseStudies === topLevelRoute;
};

export const determineDarkModeForRoute = (segments: string[], pathnameSegments: string[]) => {
  return !pagesWithLightMode.includes(segments[0]) && !is404(segments, pathnameSegments);
};
