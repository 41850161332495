import { HISTORY_SOURCE } from '@/constants/localStorage';

export const setReferrerToLocalStorage = () => {
  const documentReferrer = document.referrer;
  const isReferrerContainsHostname = documentReferrer.includes(document.location.host);

  if (documentReferrer.length > 0 && !isReferrerContainsHostname) {
    localStorage.setItem(HISTORY_SOURCE, documentReferrer);
  }
};
