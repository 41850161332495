'use client';

import Link from 'next/link';
import { routeNames } from '@/constants/routes';
import { Logo } from '@/images/svg';
import { footerContact } from '@/constants/navigations';

import { usePathname, useRouter, useSelectedLayoutSegments } from 'next/navigation';
import { getRouteSegments, is404 } from '@/helpers/darkMode';

export const Contact = () => {
  const pathname = usePathname();
  const router = useRouter();
  const segments = useSelectedLayoutSegments();

  const { labels, email } = footerContact;

  return (
    <div className="w-full mobile:w-3/6">
      {is404(segments, getRouteSegments(pathname)) ? (
        <Logo
          className="mb-[32px] hidden mobile:block cursor-pointer"
          onClick={() => router.replace(routeNames.home)}
          aria-label="Syberry"
        />
      ) : (
        <Link
          scroll={true}
          href={routeNames.home}
          className="mb-[32px] hidden mobile:block"
          aria-label="Syberry"
        >
          <Logo />
        </Link>
      )}

      <ul className="flex flex-col">
        {labels.map(({ label, fields }) => {
          return (
            <li
              key={label}
              className="mb-[20px] inline-block text-white"
            >
              <p className="font-semibold leading-[25.6px]">{label}</p>
              {fields.map(({ url, name, additionalProps }) => {
                if (url)
                  return (
                    <Link
                      key={name}
                      scroll={true}
                      prefetch={false}
                      href={url}
                      {...additionalProps}
                      dangerouslySetInnerHTML={{ __html: name }}
                    />
                  );

                return (
                  <p
                    className="leading-[25.6px]"
                    key={name}
                  >
                    {name}
                  </p>
                );
              })}
            </li>
          );
        })}
        <li className="inline-block text-white font-semibold">
          <Link
            scroll={true}
            className="transition text-white hover:text-white50 active:text-primary"
            data-testid="footer-email-link"
            href={`mailto:${email}`}
          >
            {email}
          </Link>
        </li>
      </ul>
    </div>
  );
};
