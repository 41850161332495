'use client';

import { ReactNode, useState } from 'react';
import { Footer, Header } from '@/components/Layouts';
import { HotjarScript } from '@/components/Scripts/Hotjar';
import { GoogleAnalyticsScript } from '@/components/Scripts/GoogleAnalytics';
import { GoogleTagManagerScript } from '@/components/Scripts/GoogleTagManager';
import { ReCaptchaProvider } from 'next-recaptcha-v3';
import localFont from 'next/font/local';
import { CallTracking } from '@/components/Scripts/CallTracking';
import { CookiesBanner } from '@/components/Banners/Cookies';
import { COOKIES_CONSENT_ALIASES } from '@/constants/cookiesConsent';

interface IProps {
  children: ReactNode;
}

const dmSans = localFont({
  src: [
    {
      path: '../../../public/fonts/DMSans-Regular.ttf',
      weight: '400',
    },
    {
      path: '../../../public/fonts/DMSans-Medium.ttf',
      weight: '500',
    },
    {
      path: '../../../public/fonts/DMSans-SemiBold.ttf',
      weight: '600',
    },
  ],
  variable: '--font-dmSans',
});

const dmMono = localFont({
  src: [
    {
      path: '../../../public/fonts/DMMono-Regular.ttf',
      weight: '400',
    },
    {
      path: '../../../public/fonts/DMMono-Medium.ttf',
      weight: '500',
    },
  ],
  variable: '--font-dmMono',
});

const dmMonoItalic = localFont({
  src: [
    {
      path: '../../../public/fonts/DMMono-LightItalic.ttf',
      weight: '300',
    },
    {
      path: '../../../public/fonts/DMMono-Italic.ttf',
      weight: '400',
    },
    {
      path: '../../../public/fonts/DMMono-MediumItalic.ttf',
      weight: '500',
    },
  ],
  variable: '--font-dmMono-italic',
});

export const Root = ({ children }: IProps) => {
  const [givenConsent, setGivenConsent] = useState<COOKIES_CONSENT_ALIASES | null>(COOKIES_CONSENT_ALIASES.NO);

  return (
    <html
      lang="en"
      className={`${dmSans.variable} ${dmMono.variable} ${dmMonoItalic.variable}`}
    >
      <ReCaptchaProvider
        reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
        async
      >
        <body>
          <Header />
          <main className="flex-[1_0_auto]">{children}</main>
          <Footer />
          <CookiesBanner
            givenConsent={givenConsent}
            setGivenConsent={setGivenConsent}
          />
          {givenConsent === COOKIES_CONSENT_ALIASES.YES && (
            <>
              <HotjarScript />
              <GoogleAnalyticsScript />
              <GoogleTagManagerScript />
            </>
          )}
          <CallTracking />
        </body>
      </ReCaptchaProvider>
    </html>
  );
};
