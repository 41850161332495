import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { MobileMenuHeader } from '@/components/Layouts/Header/MobileNavOverlay/components/MobileMenuHeader';
import { ContactUsButton } from '@/components/Layouts/Header/MobileNavOverlay/components/ContactUsButton';
import { MobileNavList } from '@/components/Layouts/Header/MobileNavOverlay/components/MobileNavList';
import { mobileNavLinks } from '@/constants/navigations';

interface IProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  isContactPage: boolean;
  pathname: string;
  isOpen: boolean;
}

export const MobileNavOverlay = ({ isOpen, setOpen, pathname, isContactPage }: IProps) => {
  const [activeCollapsibleMenuIndex, setActiveCollapsibleMenuIndex] = useState<number | null>(null);

  const handleSubmenuActiveLink = useCallback(() => {
    const initialActiveSubmenuIndex = mobileNavLinks.findIndex(
      ({ submenu }) => submenu?.some(({ href }) => href && pathname.startsWith(href)),
    );

    if (initialActiveSubmenuIndex === -1) {
      setActiveCollapsibleMenuIndex(null);
    } else {
      setActiveCollapsibleMenuIndex(initialActiveSubmenuIndex);
    }
  }, [pathname]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen, setOpen]);

  useEffect(() => {
    handleSubmenuActiveLink();
  }, [handleSubmenuActiveLink, pathname]);

  return (
    <div
      data-testid="mobile-nav-overlay"
      className={`
        fixed top-0 left-0 w-full overflow-hidden transition-all ease-in-out duration-300 z-100 bg-white
        ${isOpen ? 'translate-x-[0%]' : 'translate-x-[120%]'} 
      `}
    >
      <nav className="w-full h-screen flex flex-col">
        <MobileMenuHeader setOpen={setOpen} />

        <div className={`${isContactPage ? 'h-[calc(100dvh-72px)]' : 'h-[calc(100dvh-156px)]'} overflow-y-scroll`}>
          <MobileNavList
            pathname={pathname}
            activeCollapsibleMenuIndex={activeCollapsibleMenuIndex}
            setActiveCollapsibleMenuIndex={setActiveCollapsibleMenuIndex}
          />
        </div>

        {!isContactPage && <ContactUsButton />}
      </nav>
    </div>
  );
};
